import React, { useEffect, useRef, useState } from 'react';
import TopicSlide from "./topics/topicSlide";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/css";
import LinkButton from './button';
import { configuration } from '../helpers/config';
import CommonHomeCard from './commonHomeCard';
import useIsMobile from '../hooks/useIsMobile';
 
 
SwiperCore.use([Navigation, Pagination, Scrollbar]);
 
const SwiperSlider = ({ data, caseType, slidesPerView, breakpoints, formsData }) => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const handleSlideChange = () => {
    if (swiperRef.current) {
      setIsBeginning(swiperRef.current.isBeginning);
      setIsEnd(swiperRef.current.isEnd);
    }
  };
 
  const handlePrev = () => {
    if (swiperRef.current) swiperRef.current.slidePrev();
  };
 
  const handleNext = () => {
    if (swiperRef.current) swiperRef.current.slideNext();
  };
 
  // Case for "fourslide"
  const renderFourslideContent = (item, id) => {
    if (item.filename_pdf) {
      return (
        <SwiperSlide key={id} className="swiper-model">
          <div key={id}>
            <TopicSlide
              Id={`FourSlide${item?.title?.replace(/\s+/g, "")}`}
              form={item?.form_id}
              imgSrc={item?.image}
              alt_text={item.alt_text}
              filename_pdf={item?.filename_pdf}
              title={item?.title}
              cardDate={item?.cardDate}
              cardDescription={item?.desc}
            />
            {item?.cta_link && item?.cta_text && (
              <div className="cta-container">
                <LinkButton
                  Id={`FourSlidePDF${item?.title?.replace(/\s+/g, "")}`}
                  type="button_dark_arrow"
                  buttontext={item.cta_text}
                  target={item.target === "self" ? "" : "_blank"}
                  href={`${configuration.IMG_URL}${item.filename_pdf}`}
                />
              </div>
            )}
          </div>
        </SwiperSlide>
      );
    } else if (item.youtube_url) {
      return (
        <SwiperSlide key={id} className="swiper-model">
          <div className="video-container" key={id}>
            <ReactPlayer
              url={item.youtube_url}
              playing={false}
              controls={true}
              loop={true}
              volume={0}
              height="168px"
              width="300px"
              playsinline
              className="react-player"
            />
            <TopicSlide
              Id={`FourSlide${item?.title?.replace(/\s+/g, "")}`}
              form={item?.form_id}
              title={item?.title}
              cardDate={item?.cardDate}
              cardDescription={item?.desc}
            />
            {item?.cta_link && item?.cta_text && (
              <div className="cta-container">
                <LinkButton
                  Id={`FourSlideYouTube${item?.title?.replace(/\s+/g, "")}`}
                  type="button_dark_arrow"
                  buttontext={item.cta_text}
                  target={item.target === "self" ? "" : "_blank"}
                  href={item.youtube_url}
                />
              </div>
            )}
          </div>
        </SwiperSlide>
      );
    } else {
      return (
        <SwiperSlide key={id} className="swiper-model">
          <TopicSlide
            Id={`FourSlide${item?.title?.replace(/\s+/g, "")}`}
            form={item?.form_id}
            imgSrc={item?.image}
            alt_text={item?.alt_text}
            title={item?.title}
            cardDate={item?.cardDate}
            cta={{ isCta: true }}
            cardDescription={item?.desc}
            cardLink={item?.cta_link}
            cardBtnText={item?.cta_text}
            target={item?.target === "self" ? "" : "_blank"}
          />
        </SwiperSlide>
      );
    }
  };
  // Case for "threeslide" (using CommonHomeCard)
  const renderThreeslideContent = (item, id) => {
    return (
      <SwiperSlide key={id} className="swiper-model">
         <CommonHomeCard
          Id={"ThreeSlide" + item?.title?.replace(/\s+/g, "")} // .replace(/\s+/g, "") is used to remove white spaces from the id
          key={id}
          imgSrc={
            item.image
              ? configuration.IMG_URL + item.image
              : ""
          }
          imgMobileSrc={
            item.image_mob
              ? configuration.IMG_URL + item.image_mob
              : ""
          }
          youtube_url={item?.youtube_url}
          alt_text={item?.alt_text}
          imgHeight={302}
          imgWidth={544}
          cardClassName={
            "mb-[50px] md:mb-0 mr-[40px] threeColumnRadius "
          }
          contentClassName={` md:max-w-[80%] `}
          headClassName={`${
            item?.column === 1 && "text-[#000]"
          } ${
            item?.column === 3 &&
            "min-h-[75px] lg:min-h-[75px] md:min-h-[75px] "
          }`}
          modifyDesc={`h-[64px] lg:h-[64px] md:h-[64px] md:mb-[20px]`}
          modifyHead={
            "min-h-[35px] lg:min-h-[35px] md:min-h-[35px]"
          }
          descriptClass={`h-[64px] lg:h-[64px] md:h-[64px]`}
          imgMobileHeight={426}
          imgMobileWidth={768}
          cardTitle={item.title}
          cardDescription={item.desc}
          cardBtnText={item.cta_text}
          cardLink={item.cta_link}
          btnType={"button_dark_arrow"}
          formsData={formsData}
          // target={item.target === "self" ? "" : "_blank" }
          targets={item.target === "self" ? "" : "_blank"}
          form={item?.form_id}
        />
      </SwiperSlide>
    );
  };
 
  // Select which render function to use based on caseType
  const renderSlideContent = (item, id) => {
    if (caseType === "fourslide") {
      return renderFourslideContent(item, id);
    } else if (caseType === "threeslide") {
      return renderThreeslideContent(item, id);
    } else {
      // Default render logic if needed
      return renderFourslideContent(item, id);
    }
  };
  const mobile = useIsMobile();
 
  return (
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          handleSlideChange();
        }}
        onSlideChange={handleSlideChange}
        slidesPerView={slidesPerView}
        scrollbar={{ draggable: true }}
        freeMode={true}
        breakpoints={breakpoints}
      >
        {data
          ?.sort((a, b) => {
            const timestampA = parseInt(a._id.substring(0, 8), 16);
            const timestampB = parseInt(b._id.substring(0, 8), 16);
            return timestampB - timestampA;
          })
          .map((item, id) => renderSlideContent(item, id))}
 
        {console.log("first11 -- "+ isBeginning + "--- " + isEnd )}
        {!isBeginning && (
          <button
          className="swiper-button-prev"
          style={{
            left: mobile ? "0px" : "2px",
            position: "absolute",
            top: "30%",
            right:"2%",
            transform: "translateY(-50%)",
            zIndex: 10,
            cursor: "pointer",
            borderRadius: "50%",
            fontSize: mobile ? "18px" : "24px",
          }}
          onClick={handlePrev}
        >
          ❮
        </button>
        )}
        {!isEnd && (
          <button
            className="swiper-button-next"
            style={{
              right: mobile ? "-5px" : "-10px",
              position: "absolute",
              top: "30%",
              right:"2%",
              transform: "translateY(-50%)",
              zIndex: 10,
              cursor: "pointer",
              borderRadius: "50%",
              fontSize: mobile ? "18px" : "24px",
            }}
            onClick={handleNext}
          >
            ❯
          </button>
        )}
      </Swiper>
  );
};
export default SwiperSlider;