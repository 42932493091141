import { useState } from "react";
import styles from "../../styles/faq.module.css";
import Image from "next/image";
import { configuration } from "../../helpers/config";
// import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";

const AccordionGrid = ({ id, question, answer, bullets, faqs, answerTwo }) => {
  const [show, setShow] = useState(false);

  // function to toggle accordion visibility
  const openAccor = () => {
    setShow(!show);
  };
     const [isLoading, setIsLoading] = useState(true);
        const [hideImage, setHideImage] = useState(false);

  return (
    <div
      className={`faq-page-grid md:mb-[24px] mb-[16px] ${
        show ? "rounded-[5px] border-[#001151] border-[1px]" : ""
      }`}
    >
      <div
        className={`slide-grid-box flex justify-between mb-[16px] md:mb-[24px] lg:mb-[24px] ${
          show ? "border-0 rounded-none" && "" : "rounded-[5px] border-[1px]"
        } border-[#D0D0D1] `}
      >
        <h2 className={styles.faq_grid_count}>{id}</h2>
        <h2 className={styles.faq_grid_heads}>{question}</h2>
        <span onClick={openAccor} className={styles.faq_grid_signs}>
            {/* {isLoading && !hideImage && <Skeleton height={35} width={35} />} */}
                    {!hideImage && (
          <Image
            src={
              show
                ? `${configuration.IMG_STATIC_URL}assets/images/faq-page/minusAcc.svg`
                : `${configuration.IMG_STATIC_URL}assets/images/faq-page/plusAcc.svg`
            }
            width={!isLoading?35:0}
            height={!isLoading?35:0}
            alt=""
            onError={() => setHideImage(true)}
            className={isLoading ? "hidden" : "block"}
            onLoad={() => setIsLoading(false)}
          />
                    )}
        </span>
      </div>
      <div className="faq-page-descipted mb-[24px]">
        {/* {show && <p className={styles.faq_grid_desc}>{answer}</p>} */}
        {show && <div className="unreset"><div className={styles.faq_grid_desc} dangerouslySetInnerHTML={{ __html: answer }} /></div>}
        {show && answerTwo && (
          <p className={`${styles.faq_grid_desc} mt-2`}>{answerTwo}</p>
        )}
      </div>

      {bullets && (
        <div className="faq-page-descs mb-[24px]">
          {show && (
            <ul>
              {faqs &&
                faqs.map((elem, idx) => {
                  return (
                    <li
                      key={idx}
                      className={`${styles.faq_grid_desc}`}
                      style={{ marginBottom: "8px", lineHeight: "24px" }}
                    >
                      {elem.answers}
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
      )}

      {/* {
        answerTwo &&
        <div>
           {show && <p className={styles.faq_grid_desc}>{answerTwo}</p>}
        </div>
      } */}
    </div>
  );
};

export default AccordionGrid;
