import React,{useState} from "react";
import Image from "next/image";
import { configuration } from "../../helpers/config";
import Link from "next/link";
// import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";

const ExperienceIcon = ({
  icon,
  title,
  href,
  onClick,
  Id,
  alt_text,
  target,
}) => {
  // large icon with link mainly used in brand/ cms pages .
  const [isLoading, setIsLoading] = useState(true);
  const [hideImage, setHideImage] = useState(false);
  return (
    <div className="icons-grid text-center" id={Id}>
       {href ? (
        <Link
          id={Id}
          href={href || "#"} // Fallback to '#' if href is undefined
          onClick={onClick}
          passHref
          target={target}
          rel={target === "_blank" ? "noopener noreferrer" : undefined}
        >
          <div className="text-center my-10 inline-block cursor-pointer">
            {/* {isLoading && !hideImage &&  <Skeleton height={40} width={40} />} */}
            {!hideImage && (
            <Image
              id={Id}
              height={!isLoading?40:0}
              width={!isLoading?40:0}
              // className="text-center"
              className={`text-center ${isLoading ? "hidden" : "block"}`}
              onLoad={() => setIsLoading(false)}
              onError={() => setHideImage(true)}
              src={icon}
              alt={alt_text}
            />
            )}
            <p className="font-bold mt-3 text-[16px] uppercase">{title}</p>
          </div>
        </Link>
      ) : (
        <a
          className="text-center my-10 inline-block cursor-pointer"
          id={Id}
          target={target}
          rel="noopener noreferrer"
          onClick={onClick}
        >
          {/* {isLoading && !hideImage &&  <Skeleton height={40} width={40} />} */}
          {!hideImage && (
          <Image
            id={Id}
            height={!isLoading?40:0}
            width={!isLoading?40:0}
            // className="text-center"
            className={`text-center ${isLoading ? "hidden" : "block"}`}
            onLoad={() => setIsLoading(false)}
            onError={() => setHideImage(true)}
            src={icon}
            alt={alt_text}
          />
          )}
          <p className="font-bold mt-3 text-[16px] uppercase">{title}</p>
        </a>
      )}
    </div>
  );
};

export default ExperienceIcon;
